@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.titleheading{
font-size: 2rem;
font-weight: bolder;
color: #000;
}
.curvy-underline {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    color: #000;
  }
.curvy-underline::before,
.curvy-underline::after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 2px; 
    background-color: #EE266E;
    border-radius: 50%; 
    transition: width 0.3s ease; 
  }
  @media (max-width: 767px) {
.flex-mobile {
      display: flex;
      flex-direction: column;
    }
.flex-mobile .image-section {
      order: 2; 
    }
  }
.curvy-underline::before {
    left: 0;
    width: 20%; 
  }
.curvy-underline::after {
    right: 0;
    width: 20%; 
  }
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 5px;
}
.image-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
button {
  position: relative;
  overflow: hidden;
}
.active {
  position: relative;
  color: #f3528d;
}
.active::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #f3528d;
  transition: width 0.3s ease; 
}
.hide-underline {
  position: relative;
}
@media (max-width: 768px){
  .active::after {
    display: none;
  }
}
.hide-underline::after {
  content: "";
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 0; 
  height: 2px;
  background-color: #fff;
  transition: width 0.3s ease; 
}
.hide-underline:hover::after {
  width: 100%; 
}
.category-scroll-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  padding: 0 5px; 
}
.category-scroll-container button {
  flex-shrink: 0; 
  margin-right: 8px; 
  padding: 8px 12px; 
}
@media screen and (max-width: 768px) {
  .category-scroll-container button {
    padding: 6px 15px; 
    margin-right: 16px; 
  }
}
@media screen and (min-width: 768px) {
  .category-scroll-container{
 display: flex;
 justify-content: center;
  }
}
